<template>
    <div>
        <div class="card">
            <van-field name="rate" label="评分">
                <template #input>
                    <van-rate v-model="score" />
                </template>
            </van-field>
            <van-field
                    v-model="content"
                    rows="2"
                    autosize
                    label="评价"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入评价"
                    show-word-limit
            />

            <div style="margin: 10px 0 0 0;text-align: center">
                <van-button type="primary" @click="postEvaluate">提交评价</van-button>

            </div>

        </div>
    </div>
</template>

<script>
    import {Dialog, Toast} from 'vant';

    export default {
        name: "index",
        data() {
            return {
                score: 0,
                content: '',
            }
        },
        mounted() {
            this.getEvaluate();
        },
        methods: {
            getParent_id() {
                return this.$route.query.parent_id || this.$route.params.parent_id
            },
            getClass_id() {
                return this.$route.query.class_id || this.$route.params.class_id
            },
            getStudent_id() {
                return this.$route.query.student_id || this.$route.params.student_id
            },
            getEvaluate() {
                this.$axios.get('wechat/classReview?parent_id=' + this.getParent_id() +
                    '&class_id=' + this.getClass_id()||0+
                    '&student_id=' + this.getStudent_id()||0
                ).then((res) => {
                    if (res.data.code === '0000') {
                        if(res.data.content.class_review){
                            // Toast('获取成功')
                            this.score=res.data.content.class_review.score;
                            this.content=res.data.content.class_review.content;
                        }
                    }else{
                        Toast(res.data.content)
                    }
                })
            },
            postEvaluate() {
                this.$axios.post('wechat/classReview', {
                    parent_id: this.getParent_id()||0,
                    student_id: this.getStudent_id()||0,
                    class_id: this.getClass_id(),
                    score: this.score,
                    content: this.content,
                    serious_degree:this.rate
                }).then((res) => {
                    if (res.data.code === '0000') {
                        Toast('评价成功')
                    }else{
                        Toast(res.data.content)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .card {
        width: calc(100vw - 60px);
        margin: 20px;
        height: auto;
        border-radius: 10px;
        border: 1px solid gray;
        padding: 10px;
    }
</style>
